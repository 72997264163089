import React from 'react';
import { Hero } from "../../components/header/header";
import { Link } from 'react-router-dom';

function App() {
    const title = 'Error';
    document.title = global.AppName + ' - ' + title;
    return (
        <>
            <Hero title={"Cotmarsh Tannery CIC"} subtitle={"Traditional leather micro-tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="404">
                        <h2>404 - Page No Found</h2>
                        <p>
                            The page you were looking for wasn't found. Use the menu above to find what you are looking for. If the problem continues 
                            then <Link to='contact-us' title='Contact Page'>contact us</Link> to let us know.
                        </p>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;