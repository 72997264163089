import React from 'react';
import { Link } from 'react-router-dom';
import { Hero } from "../../components/header/header";

import ImageChurchillLogo from "../../assets/images/churchill-fellowship-logo.webp";
import ImageNWDLogo from "../../assets/images/north-wessex-downs-national-landscape-logo.webp";
import ImageSWFSLogo from "../../assets/images/south-west-england-fibreshed.png";

function Support() {
    const pageTitle = 'Support';
    document.title = global.AppName + ' - ' + pageTitle;
    return (
        <>
            <Hero title={"Support"} subtitle={"To help build the tannery"} 
                anchors={[{id: "funding", text: "Funding"}, {id: "tank", text: "Sponsor a tank"}, {id: "thank", text:"Thank you"}]}></Hero>

            {/* start main */}
            <div translate='no' className="main_bg">
                <div className="wrap">
                    <div className="main" id="support">
                        <section id='funding'>
                            <h2>Looking for funding</h2>
                            <p>
                                Setting up a brand new cattle hide tannery isn't cheap, so we are seeking funding to help build the facility. 
                                Cotmarsh Tannery has been set up as a not-for-profit Community Interest Company (CIC) as its key objective is education 
                                rather than being a profit generating enterprise. The tannery needs to be profitable but all profits will be 
                                put back into the business to help its future security and growth.
                            </p>
                            <p>
                                We have already bought 3 big bits of kit required for the tannery:
                            </p>
                            <ul>
                                <li>Drum - Key piece of equipment used for washing, deliming, bating, pickling, retan and fatliquoring the hides</li>
                                <li>Setting machine - Used to stretch and 'set' the hides</li>
                                <li>Buffing machine - Used to buff the grain side of the hide if a smoother finish is required</li>
                            </ul>
                            <p>
                                We have been very fortunate to have received a substantial grant from 
                                the <Link to="https://www.northwessexdowns.org.uk/" title="North Wessex Downs National Landscape">North Wessex Downs National Landscape</Link> to 
                                build the tannery building, install 3-phase electricity, a rainwater harvesting system and reedbed for waste management.
                            </p>
                            <p>
                                To get the tannery up and running we still need to raise funds for the following:
                            </p>
                            <ul>
                                <li>Lime tanks - For liming the hides to remove the hair</li>
                                <li>Tanning tanks - For tanning the hides</li>
                                <li>Five 5000l storage tanks - For storing tanning tea</li>
                                <li>Electric pallet stacker - To move the hides in and out of tanning tank and around the tannery</li>
                                <li>Drum stands - The drum needs to be mounted and raised off the ground </li>
                                <li>Pump and pipes - Required to move liquors around the tannery</li>
                                <li>Refrigerated container - To store hides in a cool, dry environment</li>
                                <li>
                                    Data logging equipment - To monitor the temperature and pH of the tanning tanks, temperature and humidity of hide storage as well
                                    as weight and monitoring the drum. We are looking use Raspberry Pi devices to do this and work with local schools to help with the 
                                    development of the project.
                                </li>
                            </ul>
                            <p>
                                We are also applying for a further grant to get the following equipment, in particular to allow us to support the 
                                wider tannery industry:
                            </p>
                            <ol>
                                <li>Steam boiler - To provide consistency in the tanning process a consistent temperature needs to be maintained</li>
                                <li>Heat exchange - To convert heat from the boiler to the tanning tea</li>
                                <li>A fleshing machine - To remove the excess flesh from the hides</li>
                                <li>A shaving machine - To shave the hides to the correct thickness</li>
                            </ol>
                        </section>
                        <section id='tank'>
                            <h2>Sponsor a tank</h2>
                            <p>
                                So how can you help? We are looking for individuals or businesses to sponsor a tank. These tanks are the 
                                heart of the tannery and critical to producing leather. The liming tank costs £5500 and the tanning tank costs £6600.
                            </p>
                            <p>
                                As the tannery is a not-for-profit CIC, any tanks bought in the tannery's name are 'asset locked'. This means that they  
                                cannot be sold or used for personal gain and if, for some reason, the tannery were to cease trading the tanks would be 
                                transferred to another not-for-profit or sold and the proceedings given to another not-for-profit. This ensures that the 
                                donation is used for the benefit of the community.
                            </p>
                            <p>
                                If you are interested in sponsoring a tank then please <Link to={"/contact-us"} title="get in touch">get in touch</Link>.
                            </p>
                            <p>
                                If a tank is too much but you would still like to support us by sponsoring some equipment then we would be very grateful of your support.
                                Other options include:
                            </p>
                            <ul>
                                <li>Tanning storage tanks - £1000 each</li>
                                <li>Data logging equipment - £2000</li>
                                <li>Leather Horses - £175 each</li>
                            </ul>
                            <p>
                              More information can be found in the <Link to='/documents/sponsor-a-tank.pdf' title='Sponsor a tank' target='_blank'>Sponsor a Tank PDF</Link>.
                            </p>
                        </section>
                        <section id='thank'>
                            <h2>Thank you</h2>
                            <p>
                                Setting up a tannery from scratch is not easy, particularly one for cattle hides. One of the things I have loved about getting into the 
                                leather industry is the openness that everyone has shown me. 
                            </p>
                            <p>
                                This wouldn't have been possible without support from a number of organisations and people, be it money, time, knowledge, support or other 
                                contributions. I am humbled by their generosity, kindness and confidence in the project:
                            </p>
                            <div className='grantaward'>
                                <div>
                                    <Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank' className='linkImage'>
                                        <img src={ImageSWFSLogo} alt='Southwest Fibreshed Logo'></img>
                                    </Link>
                                </div>
                                <div>
                                    A grant from the <Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank'>SW Fibreshed</Link> funded 
                                    the initial two day workshop to check the viability of setting up the tannery. Without it we wouldn't have got the ball rolling.
                                </div>
                                <div>
                                    <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship website' target='_blank' className='linkImage'>
                                        <img src={ImageChurchillLogo} alt='Churchill Fellowship'></img>
                                    </Link>
                                </div>
                                <div>
                                    In August 2023 I was lucky enough to receive 
                                    a <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship website' target='_blank'>Churchill Fellowship</Link> travel 
                                    scholarship that funded trips to spend 3 weeks with Matt at Traditional Tanners in Oregon, visit the Lineapelle  international leather 
                                    fair in Milan and a number of tanneries in Switzerland, Germany and Italy. What I learned from these visits has been invaluable and 
                                    has helped shape the tannery into a viable project. Details of the travel scholarship can be found on 
                                    the <Link to='/churchill-fellowship' title='Churchill Fellowship Blog'>Churchill Fellowship Blog</Link>. 
                                </div>
                                <div>
                                    <Link to='https://www.northwessexdowns.org.uk/' title='North Wessex Downs National Landscape' target='_blank' className='linkImage'>
                                        <img src={ImageNWDLogo} alt='North Wessex Downs National Landscape Logo'></img>
                                    </Link>
                                </div>
                                <div>
                                    A hugely significant "Farming in a Protected Landscape (FiPL)" grant to build the tannery building (including installing3-phase electrics, 
                                    building a reedbed for managing waste, water harvesting and more) was given by 
                                    the <Link to='https://www.northwessexdowns.org.uk/' title='North Wessex Downs National Landscape' target='_blank'>North Wessex Downs National Landscape</Link> 
                                </div>
                            </div>
                            <ul>
                                <li>
                                    Katie (<Link to='https://www.loopyewes.co.uk' title='Loopy Ewes' target='_blank'>Loopy Ewes</Link>) - I couldn't have a more supportive 
                                    wife. When I said I would love to set up a tannery the response I got was "Go on then"!
                                </li>
                                <li>Jacki Rodikis - You couldn't want for a more supportive mother-in-law</li>
                                <li>
                                    Matt Richards (<Link to='https://www.braintan.com' title='Matt Richards - Traditional Tanners' target='_blank'>Traditional Tanners</Link>) - 
                                    It is thank you to Matt that I made my first piece of leather and was hooked, so it is all his fault! If you have any interest in leather you 
                                    need to do his <Link to='https://braintan.com/product-category/kitchen-table-tannery/' title='online tanning courses' target='_blank'>online zoom courses</Link>, 
                                    it is where I started. I was also lucky enough to spent 3 weeks at Matt's micro tannery, as part of 
                                    my <Link to='../blog/US' title='US Churchill Fellowship'>Churchill Fellowship travels</Link>. His knowledge and willingness to share was 
                                    second to none. I walked away from the trip knowing I could set a tannery up and how to do it, and walked away with a new friend.
                                </li>
                                <li>
                                    Barry Knight - One of the good ones. There is probably no one in the UK with a greater knowledge of veg tanning. His generosity with his 
                                    time and his kindness has made the success of the tannery possible. I couldn't have asked for a better mentor.
                                </li>
                                <li>
                                    Karl Flower (<Link to='https://www.authenticae.co.uk/' title='Authenticae' target='_blank'>Authenticae</Link>) - Karl helped turn a mad 
                                    idea into a possibility and set me off on this path by starting with the basics of how to make leather.
                                </li>
                                <li>
                                    Kerry Senor (<Link to='https://www.leatheruk.org' title='Leather UK' target='_blank'>Leather UK</Link>) - Leather UK have help promote 
                                    the tannery and, through the British Leather Industry Development Trust, enable Barry to be a mentor for three years.
                                </li>
                                <li>
                                    Richard Daniels - Richard was one of the first to bring reed beds to the tannery industry to process the waste. His knowledge in this area 
                                    is second to none and he generosity shared everything he knew to get our reed beds set up. 
                                </li>
                                <li>Julian Shaw - Expert courier who produced some of the finest leather at John Whites and Sons.</li>
                                <li>Jon Thornes - I haven't only had support in building the tannery and making leather, Jon has advised on the business side of the tannery</li>
                                <li>
                                    There are numerous other people who have also given their support to the project, including letters of support for funding applications, not  
                                    least of all Jimmy Woodrow (<Link to='https://pastureforlife.org/' title='Pasture for Life' target='_blank'>Pasture for Life</Link>), 
                                    Emma Hague (<Link to='https://southwestenglandfibreshed.co.uk/' title='Southwest Fibershed' target='_blank'>The Southwest Fibreshed</Link>, 
                                    Sheila Cooke (<Link to='https://www.3lm.network/' title='3LM' target='_blank'>3LM</Link>) and many more.

                                </li>
                            </ul>
                            <p>
                                Also a huge thank you to everyone who opened their doors to me and give me their time during my Churchill Fellowship travels:
                            </p>
                            <ul translate="no">
                                <li>Bernhard Neuenschwander (<Link to='https://www.neuenschwander.ch/' title='Neuenschwander' target='_blank'>Neuenschwander</Link>)</li>
                                <li>Johannes Renz (<Link to='https://www.lederfabrik-renz.de/' title='August Renz' target='_blank'>August Renz</Link>)</li>
                                <li>Thomas Martin (Gerberei Martin)</li>
                                <li>Michael Kilger (<Link to='https://kilger.de/en/' title='Kilger Tannery' target='_blank'>Kilger</Link>)</li>
                                <li>Paolo Fontani (<Link to='https://www.laperlaazzurra.com' title='Conceria La Perla Azzurra' target='_blank'>Conceria La Perla Azzurra</Link>)</li>
                                <li>Giuseppe Volpi (<Link to='https://www.volpiconcerie.it/?lang=en' title='Volpi Concerie' target='_blank'>Volpi Concerie</Link>)</li>
                                <li>Sergio & Simone Dani (<Link to='https://tannery.italprogetti.it/en/' title='Italprogetti' target='_blank' translate="no">Italprogetti</Link>)</li>
                                <li>Martina Squarcini (<Link to='https://www.conceriailponte.it' title='Conceria Il Ponte' target='_blank'>Conceria Il Ponte</Link>)</li>
                                <li>Aurora Bacchi (<Link to='https://www.conceriamaryam.it/en' title='Maryam S.r.l.' target='_blank' translate="no">Maryam S.r.l.</Link>)</li>
                                <li>Giovanni Martelli (<Link to='https://www.lostivale.it' title='Lo Stivale' target='_blank'>Lo Stivale</Link>)</li>
                            </ul> 
                        </section>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;