import React, {useState, useEffect} from 'react';
import { Hero } from "../../components/header/header";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import '../home/template.css';

import ItalyPosts from "./categories/italy";
import SwitzerlandGermanyPosts from "./categories/switzerlandgermany";
import LineapellePosts from "./categories/lineapelle";
import TanneryPosts from "./categories/tannery";
import TanneryBuildPosts from "./categories/tannerybuild";
import UKPosts from "./categories/uk";
import USPosts from "./categories/us";

function Blog() {
    const { category } = useParams();
    const navigate = useNavigate();
    const [BlogCopy, setBlogCopy] = useState(null);   
    const [TripSummary, setTripSummary] = useState(null);   
    const [pageVariables, setPageVariables] = useState({
        pageTitle: "Blog",
        postClass: "blogs"
    });
 
    useEffect(() => {
         document.title = global.AppName + ' - ' + pageVariables.pageTitle;
     }, [pageVariables.pageTitle]);
 
    useEffect(() => {
        selectTrip();
    }, [category]);
    
    function selectTrip(){     
        var postClass = category ? "blogposts" : "blogs";
        if (category) {
            // Load blog posts for the selected category
            switch(category.toLowerCase()) {
                case "italy":
                    setPageVariables({postClass: postClass, pageTitle: "Italy Posts"});
                    setTripSummary(<ItalyPosts mode={"full"} />);
                    break;
                case "lineapelle":
                    setPageVariables({postClass: postClass, pageTitle: "Lineapelle Posts"});
                    setTripSummary(<LineapellePosts mode={"full"} />);
                    break;
                case "switzerland-germany":
                    setPageVariables({postClass: postClass, pageTitle: "Switzerland and Germany Posts"});
                    setTripSummary(<SwitzerlandGermanyPosts mode={"full"} />);
                    break;
                case "tannery":
                    setPageVariables({postClass: postClass, pageTitle: "Tannery Posts"});
                    setTripSummary(<TanneryPosts mode={"full"} />);
                    break;
                case "tannery-build":
                    setPageVariables({postClass: postClass, pageTitle: "Tannery Build Posts"});
                    setTripSummary(<TanneryBuildPosts mode={"full"} />);
                    break;
                case "uk":
                    setPageVariables({postClass: postClass, pageTitle: "UK Posts"});
                    setTripSummary(<UKPosts mode={"full"} />)
                    break;
                case "us":
                    setPageVariables({postClass: postClass, pageTitle: "US Posts"});
                    setTripSummary(<USPosts mode={"full"} />)
                    break;
                case "all":
                    setPageVariables({postClass: postClass, pageTitle: "All Posts"});
                    setTripSummary(
                        <>
                            <USPosts mode={"full"} />
                            <LineapellePosts mode={"full"} />
                            <SwitzerlandGermanyPosts mode={"full"} />
                            <ItalyPosts mode={"full"} />
                            <UKPosts mode={"full"} />
                            {/* <TanneryBuildPosts mode={"full"} />
                            <TanneryPosts mode={"full"} /> */}
                        </>
                    );
                    break;
                default:
                    // Load blog posts for the specific post
                    setPageVariables({postClass: postClass, pageTitle: "Post #" + category});
                    import(`./posts/${category}.js`)
                        .then(module => setBlogCopy(() => module.default))
                        .catch(() => console.error('Error loading module:'))
                    break;
            }
        }
        else {
            setPageVariables({postClass: postClass, pageTitle: "All Posts"});
            setTripSummary(
                <>
                    <USPosts mode={"summary"} />
                    <LineapellePosts mode={"summary"} />
                    <SwitzerlandGermanyPosts mode={"summary"} />
                    <ItalyPosts mode={"summary"} /> 
                    <UKPosts mode={"summary"} />
                    {/* <TanneryBuildPosts mode={"full"} />
                    <TanneryPosts mode={"full"} /> */}
                </>
            );
        }
    }

    const handleGoBack = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        navigate(-1); // Navigate to the previous page in history
    };

    return (
        <>
            <Hero title={"Blog"}></Hero>
            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id={pageVariables.postClass}>
                        <div>
                            <h2>{pageVariables.pageTitle}</h2>
                        </div>
                        {TripSummary ? TripSummary : BlogCopy ? <BlogCopy /> : <div>no blog found</div>}
                        <div className="clear"></div>
                        <Link to="#" onClick={handleGoBack} title='Go back' className='linkArrow'><FontAwesomeIcon icon={faArrowLeft} /> <span>Go back</span></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog;