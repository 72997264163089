import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// A custom component that scrolls to the top of the page
// when the location changes
function ScrollToPosition({ children }) {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]); // Only run this effect when the location changes

  useEffect(() => {
    if (location.hash) {
        const element = document.getElementById(location.hash.replace('#', ''));
        if (element) {
            const headerOffset = document.querySelector('header')?.offsetHeight || 0; // Adjust to your header selector
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - headerOffset;

            setTimeout(() => {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 50); // Delay in milliseconds
        }
    }
}, [location.hash]);

  return children; // Return the children unchanged
};

export default ScrollToPosition;