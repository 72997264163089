import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { setCookie, getCookie } from "../../utils/cookies";

import './cookieconsent.css';

const TRACKING_ID = "G-8MPHPT8J4W";
const COOKIE_NAME = 'CT_cookies';
const COOKIE_EXPIRATION_DAYS = 730; // 2 years (730 days)

function CookieConsent() {
    const [cookieConsent, setCookieConsent] = useState({ hasConsented: false, actioned: false, feedbackMessage: '' });
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        //gets the cookie
        const cookie = getCookie(COOKIE_NAME);
    
        // Set userHasConsented based on the value of the CT_cookies cookie
        if (cookie) {        
            setCookieConsent({ hasConsented: cookie === 'true', actioned: true}) // Set consented on cookie value & set actioned to true
        } else {
            // No cookie means user has not consented
            setCookieConsent({ hasConsented: false, actioned: false });
        }
    }, []);

    useEffect(() => {
        if (cookieConsent.hasConsented) {
            // Google Analytics tracking
            ReactGA.initialize(TRACKING_ID, {
                gaOptions: {
                    anonymizeIp: true
                }
            });
            // Clarity tracking
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "lsy05355ni");
        }        
    }, [cookieConsent.hasConsented]);

    function handleCookie(isConsented) {        
        // Set the cookie
        setCookie('CT_cookies', isConsented, COOKIE_EXPIRATION_DAYS);        
        // Update the state to reflect that the user has 
        setCookieConsent({ hasConsented: isConsented, actioned: false }); //temporarily keep actioned = false
        if (isConsented) {
            setCookieConsent(prevState => ({ ...prevState, feedbackMessage: 'Thank you for accepting cookies!' })); 
        }
        else {
            setCookieConsent(prevState => ({ ...prevState, feedbackMessage: 'You have declined cookies.' }));
        }
            
        // Clear feedback message after a few seconds and then set actioned to true
        setTimeout(() => {
            setIsClosing(true); // Start closing transition
            setTimeout(() => {
                setCookieConsent(prevState => ({ ...prevState, actioned: true, feedbackMessage: '' })); // Set actioned to true after feedback
            }, 1000); // Match this timeout with the CSS transition duration
        }, 2000);
    };

    if (cookieConsent.actioned) {
        // Don't render the bar if cookie has been actioned
        return null; 
    }

    return (
        <div className={`cookies-bar ${isClosing ? 'hidden' : ''}`}  role="alert" aria-live="polite">
            {!cookieConsent.feedbackMessage && ( // Only show buttons if there is no feedback message
                <>
                    <p>This website uses some essential cookies.<br />
                        Accept or decline additional cookies that are useful to us (we anonymise IP addresses).</p>
                    <button onClick={() => handleCookie(true)}>Accept</button>
                    <button onClick={() => handleCookie(false)}>Decline</button>
                </>
            )}
            {cookieConsent.feedbackMessage && <p className="feedback-message">{cookieConsent.feedbackMessage}</p>}
        </div>
    );
};

export default CookieConsent;