import React from 'react';
import { Hero } from "../../components/header/header";
import { Link } from 'react-router-dom';

import ImageCows from "../../assets/images/cows.webp";

function Home() {
    document.title = global.AppName;
    return (
        <>        
            <Hero title={"Cotmarsh Tannery CIC"} subtitle={"Traditional leather micro-tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="home">
                        <h2>The Project</h2>
                        {/* <h3>some things you should know about us</h3> */}
                        <section>
                            <p>
                                In 2023 we bought <Link to='https://www.greatcotmarshfarm.co.uk' title='Great Cotmarsh Farm website' target='_blank'>Great Cotmarsh Farm</Link> after
                                10 years of owning livestock and being graziers in The Cotswold. The farm has given us an opportunity to do more than just have a secure base for the 
                                livestock. One of the main projects is to build a Micro Veg Tannery for producing high provenance leather from cattle hides.  
                            </p>
                            <p>
                                We have always farmed with nature at the heart of what we do, having always used our rare breed livestock for conservation grazing. When not conservation 
                                grazing species rich grasslands we practice tall grass regenerative grazing to help boost soil health and biodiversity.
                            </p>
                            <img src={ImageCows} alt='James and Katie with cows'></img>
                            <p>
                                When we bought the farm James started looking into the viability of setting up an a traditional veg tannery to produce high quality leather 
                                from cattle hides, with the support of 
                                a <Link to='https://southwestenglandfibreshed.co.uk/' title='South West Fibreshed' target='_blank'>South West Fibreshed</Link> grant. 
                                Katie has been producing high end garments from the wool off the sheep for years with a successful 
                                business, <Link to='https://www.loopyewes.co.uk' title='Loopy Ewes website' target='_blank'>Loopy Ewes</Link>, as well as having sheepskins made. 
                                A tannery would complete the fibre story.
                            </p>
                            <p>
                                So Cotmarsh Tannery was created as a not for profit Community Interest Group (CIC). The objective of the tannery is threefold:
                            </p>
                            <ol>
                                <li>
                                    Produce high quality, fully traceable (down to the individual cow) veg tanned cow leather. This leather will be aimed at 
                                    small brands, artisan leather workers and makers, and as farmer returns, enabling farmers to generate another income stream 
                                    from their cattle enterprise.
                                </li>
                                <li>
                                    To be part of the field to fibre story and educational experience we are building on the farm, to promote natural fibres, 
                                    including veg tanned leather and wool, and botanical dyes. We have launched a workshop for fashion students on the farm, 
                                    which will expose students to the very beginning of the fashion supply chain and help them forge a deeper connection between 
                                    nature and our clothes. By seeing in practice how leather, wool and botanical dyes can be grown within a sustainable farming 
                                    system in the UK, students will be able to understand how meaningful relationships with the land and the farmers who grow our 
                                    food and fibre, can enable a healthy more sustainable model for fashion. We are building a classroom on the farm to develop this 
                                    programme further.
                                </li>
                                <li>
                                    To support the creation of other micro tanneries. The tannery will be open to anyone who wants to set up their own micro
                                    tannery to get hands on learning by spending short periods of time as interns or as an apprentice for longer durations. 
                                    The tannery will act as a regional hub for other tanners to allow the sharing of equipment e.g. a fleshing machine. 
                                    These expensive machines are a barrier to entry for micro tanneries so other tanners can either hire them for the day or 
                                    the tannery will offer a fleshing service.
                                </li>
                            </ol>
                            <h3>Churchill Fellowship</h3>
                            <p>
                                In 2023 James was successful in applying for 
                                a <Link to='https://www.churchillfellowship.org' title='Churchill Fellowship website' target='_blank'>Churchill Fellowship</Link> travel
                                scholarship. Details of the travel scholarship and a blog from his travels in March, September and November 2024 can be  
                                found <Link to="/churchill-fellowship" title='Churchill Fellowship Travels'>here...</Link>
                            </p>
                        </section>
                        {/*  <div className="content_bg">
                            <div className="main_pic">
                                <a className="btn" href="#">The Tannery</a>
                            </div>
                        </div> */}
                        {/* end tabs_style */}
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

            {/* start work-page */}
            {/* <div className="work_bg">
                <div className="wrap">
                    <div className="work" id="work">
                        <div className="main_text1">
                            <h2>our latest work </h2>
                            <h3>some projects we've worked on of late</h3>
                        </div>		 */}
                        
                        {/* <div className="grids_of_2">
                            <div className="grid_1_of_2">
                                <div className="grid_pic">
                                    <img src={ImagePic1} alt="" />
                                </div>
                                <div className="grid_text">
                                    <h2>runner</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                </div>
                            </div>
                            <div className="grid_1_of_2">
                                <div className="grid_pic">
                                    <img src={ImagePic2} alt=""></img>
                                </div>
                                <div className="grid_text">
                                    <h2>velo</h2>
                                    <p>There are many variations of passages of Lorem Ipsum but the majority have suffered  in some form, by injected humour, or randomised words which don't  when an unknown printer took look even slightly believable. </p>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>	
                        <div className="grids_of_2">
                            <div className="grid_1_of_2">
                                <div className="grid_pic">
                                    <img src={ImagePic3} alt=""></img>
                                </div>
                                <div className="grid_text">
                                    <h2>harriers</h2>
                                    <p>There are many variations of passages of Lorem Ipsum but the majority have suffered  in some form, by injected humour, or randomised words which don't  when an unknown printer took look even slightly believable. </p>
                                </div>
                            </div>
                            <div className="grid_1_of_2">
                                <div className="grid_pic">
                                    <img src={ImagePic4} alt=""></img>
                                </div>
                                <div className="grid_text">
                                    <h2>striders</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>	 */}
                        
                        {/* <div className="wrk_btn">
                            <a className="popup-with-zoom-anim" href="#small-dialog">view more projects</a>
                        </div>
                    </div>
                </div>
            </div> */}


        </>
    );
}

export default Home;