import React, { useState, useEffect }  from 'react';
import { Hero } from "../../components/header/header";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import '../home/template.css';
import './newsletter.css';

import ImageLogo from "../../assets/images/cotmarsh-tannery-logo.webp";

function Newsletter() {
    const { release } = useParams();
    const initPageTitle = 'Newsletter Archive';
    const [NewsletterContent, setNewsletterContent] = useState(null);
    const [pageTitle, setPageTitle] = useState(initPageTitle);
    // document.title = global.AppName + ' - ' + pageTitle;

    useEffect(() => {
        document.title = global.AppName + ' - ' + pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        if (release) {
            import(`./newsletters/${release}.js`)
                .then(module => setNewsletterContent(() => module.default))
                .catch(err => console.error(`Failed to load newsletter content for release ${release}:`, err));

            setPageTitle('Newsletter #' + release.replace(/^0+/, ''));
        }
        else {
            setPageTitle(initPageTitle);
        }
    }, [release]);

    function renderBody(){
        if (release && NewsletterContent) {
            return (
                <>
                    <div className='newsletter'>
                        <div className="newsletter-logo">
                            <img src={ImageLogo} alt="Cotmarsh Tannery CIC Logo"></img>
                        </div>                                
                        <NewsletterContent />
                        <div className='newsletter-signature'>
                            <ul className="icons">
                                <li><Link to="https://www.instagram.com/cotmarshtannery/" title="Instagram" className="icon" target="_blank"><FontAwesomeIcon icon={faInstagram} /><span className="label">Instagram</span></Link></li>
                                <li><Link to="https://linkedin.com/company/cotmarshtannery" title="LinkedIn" className="icon" target="_blank"><FontAwesomeIcon icon={faLinkedin} /><span className="label">Linkedin</span></Link></li>
                                <li><Link to="https://www.facebook.com/cotmarshtannery/" title="Facebook" className="icon" target="_blank"><FontAwesomeIcon icon={faFacebook} /><span className="label">Facebook</span></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='newsletter-footer'>
                        <div className='image-centre'><img src={ImageLogo} alt="Cotmarsh Tannery CIC Logo"></img></div>
                        Copyright (C) {new Date().getFullYear()} Cotmarsh Tannery CIC. All rights reserved.
                    </div>
                    <br />
                    <div>
                        <Link to="/newsletter" title="Back to Newsletters"><FontAwesomeIcon icon={faArrowLeft} /> Back to Newsletters</Link>
                    </div>
                </>
            )
        } 
        else {
            return (
                <>                
                    <p>Welcome to the Cotmarsh Tannery CIC newsletter archive.</p>
                    <p>
                    </p>
                    <ul>
                        <li><Link to="/newsletter/01" title="Newsletter #01">Newsletter #01</Link></li>
                    </ul>
                </>
            )
        }
    }

    return (
        <>
            <Hero title={"Newsletters"}></Hero>
            {/* start main */}
            <div className="main_bg grey">
                <div className="wrap">
                    <div className="main">
                        <div>
                            <h2>{pageTitle}</h2>
                            {renderBody()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Newsletter;