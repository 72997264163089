import React from 'react';
import BlogPost from "../../../components/blogpost/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageThomasWareTanYard from "../../../assets/images/blog/thomas-ware-tan-yard.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>
                    <h3><Link to='/blog/uk' title='Tannery build Blog'>UK Tannery Visits</Link></h3>
                    <h4>Visits to the remaining UK tanneries and leather finishers</h4>
                    <p>
                        There is still a leather industry in the UK, though drastically reduced from its heyday. As part of the process to research and design
                        the tannery I have visited several of the remaining tanneries and leather finishers in the UK:
                    </p>
                    <ul>
                        <li>
                            <Link to='https://www.billytannery.co.uk' title='Billy Tannery' target='_blank'>Billy Tannery</Link> - 
                            Goat and deer skin tannery
                        </li>
                        <li>Spires (Claytons) Tannery - CLOSED DOWN</li>
                        <li>Institute of Creative Leather Technology (ICLT) - CLOSING</li>
                        <li><Link to='http://www.blenkinsopleathers.com' title='Blenkinsop Leathers (Finishers)' target='_blank'>Blenkinsop Leathers (Finishers)</Link></li>
                        <li>Pittards (Tannery) - CLOSED DOWN</li>
                        <li><Link to='https://www.metropolitanleather.com' title='Metropolitan Leathers (Finishers)' target='_blank'>Metropolitan Leathers (Finishers)</Link></li>
                        <li><Link to='https://thomasware.co.uk' title='Thomas Ware' target='_blank'>Thomas Ware</Link> - Cattle hide veg tannery</li>
                        <li>John White and Sons (Curriers) - CLOSED DOWN</li>
                    </ul>
                    <div className='bloglinks'>
                        <Link to='/blog/uk' title='UK Tanneries and Finishers' className='linkImage'><img src={ImageThomasWareTanYard} alt='Thomas Ware Tan Yard'></img></Link>
                        <Link to='/blog/uk' title='UK Tanneries and Finishers' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </section>
                )
            case "full":
            default:
                return (
                    <section>
                    <BlogPost id={"01"}/>
                    <BlogPost id={"02"}/>
                    <BlogPost id={"03"}/>
                    <BlogPost id={"04"}/>
                    <BlogPost id={"05"}/>
                    <BlogPost id={"26"}/>
                    <BlogPost id={"27"}/>
                    <BlogPost id={"28"}/>
                </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;