import React from "react";
import { Hero } from "../../components/header/header";
import { Link } from "react-router-dom";

function Makers() {
  const pageTitle = "Makers Directory";
  document.title = global.AppName + " - " + pageTitle;
  return (
    <>
      <Hero
        title={"Cotmarsh Tannery CIC"}
        subtitle={"Traditional leather micro-tannery"}
      ></Hero>

      {/* start main */}
      <div className="main_bg">
        <div className="wrap">
          <div className="main">
            <h2>Makers Directory</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Makers;