import React from 'react';
import { Hero } from "../../components/header/header";
import {Link} from 'react-router-dom';

import JotFormEmbed from "../../components/JotFormEmbed";

function Survey() {
    const pageTitle = 'Churchill Fellowship';
    document.title = global.AppName + ' - ' + pageTitle;
    return (
        <>            
            <Hero title={"Leather Survey"} subtitle={"I need your help"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="survey">
                        <h2>Take the Survey</h2>
                        <p>To help us understand a bit more about the need for a tannery it would be really helpful if you could complete the survey below for us.</p>
                        <p>Your time is really appreciated, thank you.</p>

                       <JotFormEmbed formid={'233316314678054'} formtitle={'Veg Tannery Survey'}></JotFormEmbed>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Survey;