import { useState } from "react";
import { Hero } from "../../components/header/header";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Contact() {
    const pageTitle = 'Contact Us';
    document.title = global.AppName + ' - ' + pageTitle;

    const initialFormData = {
        firstname: '',
        surname: '',
        emailto: 'james@cotmarshtannery.co.uk',
        emailfrom: '',
        phone: '',
        title: '',
        message: '',
        subscribe: false,
        captcha: '',
        websiteid: 'CotmarshTannery'
    };
    const [formData, setFormData] = useState(initialFormData);

    function resetFormData() {
        setFormData(() => initialFormData);
    };
    const [submit, setSubmit] = useState({submitting: false, submittedAttempted: false});

    const [errormessage, setErrormessage] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    function handleChange(e) {
        // set the form data on form field change and validate
        const { name, type, checked, value } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });    
        validateValues();    
      };

    function validateValues(submitting) {        
        var valid = false;
        let errormessage = {};

        // If form was submitted and field changed after submitted once (so already has errors show) the validate
        if(submitting || submit.submittedAttempted) {
            var field = document.getElementById('firstname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.firstname = "First name is required";
            } else {
                field.classList.remove('error');
            }

            field = document.getElementById('surname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.surname = "Surname is required";
            } else {
                field.classList.remove('error');
            }
            
            field = document.getElementById('emailfrom');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.emailFrom = "Email address is required";
            } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(field.value)) {
                errormessage.emailFrom = "Invalid email address";
            } else {
                field.classList.remove('error');
            }
            
            field = document.getElementById('message');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.message = "A message is required";
            } else if (field.value.length < 4) {
                errormessage.message = "A message has to be over 4 characters";
            } else {
                field.classList.remove('error');
            }

            // check if all validation passed (fail safe)
            if (Object.keys(errormessage).length === 0)
            {
                valid = true;
            }
            setErrormessage(errormessage);
        }
        return valid;
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmit({submitting: true, submittedAttempted: true});

        if(validateValues(true)) {

            const token = await executeRecaptcha("contact_form");

            var formSubmitData = formData;
            formSubmitData.captcha = token;

            //   fetch('https://creweallen-function.azurewebsites.net/api/SendEmail', { 
            //   fetch('http://localhost:7071/api/SendEmail', { 
            fetch(process.env.REACT_APP_API_ENDPOINT + '/SendEmail', { 
                method: "POST",
                body: JSON.stringify(formSubmitData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=> {
                // Hide the form whilst showing submit outcome
                document.getElementById('contact-form').style.display = 'none';
                if (response.status === 'success') {
                    // submit sucessful so reset form and set subitted flag to false for full form reset
                    resetFormData()
                    document.getElementById('contact-form-message-success').style.display = 'block'
                } else {
                    document.getElementById('contact-form-message-fail').style.display = 'block'
                    setFormData({...formData, errormessage: response.message});
                }
            }).catch ((error) => {
                // hide form whilst showing error message
                document.getElementById('contact-form').style.display = 'none';
                document.getElementById('contact-form-message-fail').style.display = 'block'
                setFormData({...formData, errormessage: error.message});
                // setFormData({...formData, errormessage: "A general error has occured."});
            }).finally(() => {
                setSubmit({submitting: false, submittedAttempted: false}); // Re-enable the button
            });
        }
        else {
            setSubmit(prevState => ({
                ...prevState, submitting: false
            }));
        }

    };

    function closeMessage(e) {
        // close error / sucess message and reshow the form (either reset or with failed values in fields)
        e.target.parentNode.style.display='none';
        document.getElementById('contact-form').style.display = 'block';
    }

    return(
        <>            
            <Hero title={"Cotmarsh Tannery CIC"} subtitle={"Traditional leather micro-tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="contact">
                        <h2>Contact Us</h2>

                        <div id="contact-form-message-success" style={{display: 'none'}}>
                            <h4>Thank you</h4>
                            <p>
                                Your message has been sent and we will get back to you as soon as we can.
                            </p>
                            <input type="submit" className="primary" value="Close" onClick={closeMessage} />
                        </div>                        
                        <div id="contact-form-message-fail" className="error" style={{display: 'none'}}>
                            <h4>Error</h4>
                            <p>
                                There appears to be a problem with the contacts form, please try again.
                            </p>
                            {
                                process.env.NODE_ENV === 'development' && 
                                <p>
                                    Error message: {formData.errormessage}
                                </p>
                            }
                            <input type="submit" className="primary" value="Close" onClick={closeMessage} />
                        </div>
                        {/* TODO form validation */}
                        <form id="contact-form" onSubmit={handleSubmit} method="POST" noValidate >
                            <p>
                                If you want to get in touch with us then please use the contact form below:
                            </p>
                            <div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="firstname">Firstname<span className="error">*</span></label>
                                    <input type="text" name='firstname' id='firstname' placeholder='First name' value={formData.firstname} onChange={handleChange}/>
                                </div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="surname">Surname<span className="error">*</span></label>
                                    <input type="text" name='surname' id='surname' placeholder='Surname' value={formData.surname} onChange={handleChange} />
                                </div>
                                <div className="col-6 col-12-xsmall">        
                                    <label htmlFor="emailfrom">Email address<span className="error">*</span></label>
                                    <input type="email" name="emailfrom" id="emailfrom" placeholder="Email" value={formData.emailfrom} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="message">Message<span className="error">*</span></label>
                                    <textarea name="message" id="message" placeholder="Enter your message" rows="6" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <div className="col-12">
                                    <label className="checkbox-container">
                                        <input 
                                            type="checkbox" 
                                            name="subscribe" 
                                            id="subscribe" 
                                            checked={formData.subscribe} 
                                            onChange={handleChange} 
                                        />
                                        <span className="custom-checkbox"></span>
                                        <span className="line-height-1">Subscribe to newsletter <span className="small-text block">(you won't get many emails)</span></span>
                                    </label>
                                </div>
                                <div className="col-12">
                                    <input type="submit" className="primary" value="Send Message" disabled={submit.submitting}/>
                                </div>
                                <div id="ErrorSummary" className="col-12 error">
                                    <ul>
                                        {Object.keys(errormessage).map((key) => (
                                            <li key={key}>{errormessage[key]}</li>
                                        ))}
                                    </ul>
                                </div>
                                {/* <div>Process: {process.env.REACT_APP_API_ENDPOINT + '/SendEmail'}</div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

// wrap the app component with the GoogleReCaptchaProvider
export default Contact