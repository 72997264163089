import React from "react";
import { Link } from "react-router-dom";
import { Hero } from "../../components/header/header";

import ImageChurchillLogo from "../../assets/images/churchill-fellowship-logo.webp";
import ImageNWDLogo from "../../assets/images/north-wessex-downs-national-landscape-logo.webp";
import ImageSWFSLogo from "../../assets/images/south-west-england-fibreshed.png";

function Story() {
  const pageTitle = "Our Story";
  document.title = global.AppName + " - " + pageTitle;
  return (
    <div>
      <Hero title={"Our Story"} subtitle={"How the tannery came to be"}></Hero>

      {/* start main */}
      <div translate="no" className="main_bg">
        <div className="wrap">
          <div className="main" id="support">
            <section id="2022">
              <h2>2022</h2>
              <p>
                Katie and I spent 10 years grazing for other landowners across The Cotswold before we were fortunate enough to 
                buy <Link to='https://www.greatcotmarshfarm.co.uk' title='Great Cotmarsh Farm website' target='_blank'>Great Cotmarsh Farm</Link>, 
                a 170-acre farm at the foot of the North Wessex Downs, at the end of 2022.
              </p>
              <p>
                Over that time Katie built up a <Link to='https://www.katiecotmarsh.co.uk' title='Katie Cotmarsh website' target='_blank'>knitwear business</Link> starting 
                from learning to sew, to becoming an award-winning designer / maker with her collection being on sale in Liberty London as part of a 
                collaboration with the Crafts Council. She is passionate about the importance of natural fibre and reconnecting fashion to the soil.
              </p>
              <p>
                The farm came with around 15 barns of various sizes as the previous farmers had housed their cows for 6 months a year due
                to the farm being on heavy clay. Never having put an animal in a barn we weren't quite sure what to do with them. Not long after
                we bought the farm, we were had a rare night out and, over a beer, I said I fancied turning a barn into a traditional tannery
                making leather from cattle hides to return to farmers. In typical fashion the response I got was “go on then”. So started
                a journey.
              </p>
            </section>
            <section id="2023">
              <h2>2023</h2>
              <p>
                I had no idea how leather was made, not a clue. Over the next couple of months, I got lost in the depths of the internet watching 
                YouTube and flicking through social media. Somewhere along the line I got my first big break when I stumbled across a tanner called Matt 
                Richards (<Link to='https://braintan.com/' title='Traditional Tanners' target='_blank'>Traditional Tanners</Link>), in the US. Matt has a 
                micro-tannery in Oregon and has been tanning hides for decades. Following the broad adoption of Zoom during COVID he decided to run an online 
                Zoom tanning course, to which I signed up to the first one. The course includes everything you needed to make a 30cm x 30cm piece of leather, 
                he even sends out a piece of deer skin to tan (I swapped in one of our sheepskins). Over a period of a month there are 1 or 2 Zoom sessions a 
                week going through each step of the tanning process. Matt has an incredible breadth and depth of knowledge, and he was happy to share it.
              </p>
              <p>
                Tanning leather is a multi-step process with the key one being the actual tanning of the skin / hide. In traditional tanning this preservation 
                step is done using extracts from tree barks and other natural materials, in the last 100 years that has mostly been replaced by Chrome tanning. 
                Just over halfway through Matt's course you put your prepared skin into tannins for the first time. The only way of describing the change in the 
                soft, swollen, white skin over the first couple of hours is “magic”! It quickly starts to take on the look and texture of leather and then over 
                the next couple of weeks in the tannins it becomes the leather than you and I know. 
              </p>
              <p>
                Walking away with a piece of leather after a month hooked me and then things started to evolve rapidly. 
              </p>
              <p>
                The UK used to lead the world in producing leather, there was a tannery in almost every town, on this expertise we built the reputation for 
                British shoes and saddlery. Since Chrome tanning became the standard way to make leather and tanneries became bigger, more efficient our standing 
                in the leather industry quickly declined until now when only a few tanneries exist in the UK, even fewer veg tanneries. Traditional oak bark tanning 
                is now classified as critically endangered on 
                the <Link to='https://www.heritagecrafts.org.uk/skills/redlist/' title='The red list' target='_blank'>Heritage Crafts red list</Link>. We are in 
                danger of loosing the knowledge from the country completely as the last tanning experts retire.
              </p>
              <p>
                In 2023 I chanced my arm and applied for a <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship website' target='_blank'>Churchill Fellowship</Link> travel 
                scholarship to support me setting up a tradition veg tannery. The Churchill Fellowship was set up from money donated by everyday people right across 
                the country when Winston Churchill died. They give out travel scholarships annually for people to travel abroad, gain knowledge and bring it back 
                to the UK. The subjects that people research are humbling including in my cohort such things as “Improving palliative care for teenagers and 
                young adults”, “Addressing social isolation among asylum seekers in the UK”, “Prevention of suicide in care-experienced children and young people”. 
                Amongst titles like this my “Developing a micro-tannery model to vegetable tan British cattle hides” seemed a bit out of place, but rather surprisingly 
                I was awarded the scholarship.
              </p>
            </section>
            <section id="2024">
              <h2>2024</h2>
              <p>
                So, in 2024 I spent 5 weeks travelling to 4 different countries to visit 12 tanneries. My first trip was to spend 3 weeks as an intern at Matt's tannery 
                in the US. This was an incredibly experience, one of the best things I have every done. Matt is one of the nicest guys you will ever meet, and he was 
                incredibly generous with his time and knowledge. The 3 weeks were very hands one, on day two I was running 10 cattle hides through his large fleshing 
                machine and by the end of my time there we had produced my first leather from cattle hides. It was so helpful to see what our tannery might look like 
                and the work that was involved to run a micro-tannery. Also, a life friend was made.
              </p>
              <p>
                In September I visited <Link to='https://www.lineapelle-fair.it/en' title='Lineapelle' target='_blank'>Lineapelle</Link>, the international leather 
                fair in Milan. This was an eye opener. I had never though about the scale of leather as a material in the world or thought about the technology 
                and money involved in the footwear industry. The main purpose of my visit was to speak to traditional veg tanneries and line up visits for later 
                in the year.
              </p>
              <p>
                The last two trips were almost back-to-back in November. I spent a week visiting traditional tanneries in Switzerland and Germany. Then, fittingly, 
                I spent my final week of travels in the tannery district of Italy visiting two tanneries a day.
              </p>
              <p>
                The knowledge I brought back from my travels cannot be understated. The 3 weeks with Matt took my knowledge of leather tanning from basic to the 
                level where I am confident to start a tannery. Visiting so many different tanneries gave me insight into how to build my own, what things to think 
                about, what mistakes to avoid and how to design one that would work for me. 
              </p>
              <p>
                I got another big break in 2023. The UK leather industry generously gave me a grant to fund probably the best veg tanning expert in the country to be 
                a mentor over 3 years. Barry Knight started on the tannery floor in his teens and now is 'retired' but still travels the world advising tanneries. 
                With his involvement I now have the confidence to produce high quality leather.
              </p>
              <p>
                The tannery project has hugely evolved since that first conversation over a beer 2 years ago. With Katie's passion for wool and natural fibre and 
                our join passion for education about fibre we have started to have schools, colleges and universities visit the farm to see the journey of 
                natural fibre from the field to product. Katie put in an 800-plant natural botanical dye garden last year and we have aspirations of building 
                a small batch natural dye kitchen for farmers / makers to dye up to 30kg of wool at a time.
              </p>
              <p>
                The tannery will not only be a key part in this education about the importance of natural fibre to the next generation but it is also going to be 
                a hub for other micro-tanneries, or would be tanners, to come see how it is done, gain some experience and as a equipment sharing centre; some of 
                the key bits of equipment cost more than £50,000 and are only used for a few hours a month at the scale I am tanning at.
              </p>
              <p>
                The big news at the end of last year was that we were successful in applying for a FiPL grant to build the tannery building and a classroom on the farm. This was a pivotal moment as it meant the tannery has gone from an idea to a reality. Building work started the first week in January and will be complete by the end of March. I have some of the kit that is required to get the tannery up and running but still have more equipment to find money for. The expensive machinery I am hoping will be funded through a Heritage Lottery grant, and I am running a ‘Sponsor a tank’ fundraising drive to get the equipment I need to start tanning in the meantime.

              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
